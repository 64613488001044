<template>
    <section class="how section-offset">
        <h2 class="how__title section-title">{{ $t("register.howTo.title") }}</h2>
        <ul class="how-list list-reset">
            <li class="how-list__item how-item">
                <span class="how-list__item-count">1</span>
                <p class="how-item__desc">
                    {{ $t("register.howTo.firstDescription") }}
                </p>
                <router-link :to="{ path: '/academy' }">
                    <button class="how-item__more glob-btn btn-reset" type="button">
                        {{ $t("register.howTo.more") }}
                    </button>
                </router-link>
            </li>
            <li class="how-list__item how-item">
                <span class="how-list__item-count">2</span>
                <p class="how-item__desc">
                    {{ $t("register.howTo.secondDescription") }}
                </p>
                <router-link :to="{ path: '/academy' }">
                    <button class="how-item__more glob-btn btn-reset" type="button">
                        {{ $t("register.howTo.more") }}
                    </button>
                </router-link>
            </li>
            <li class="how-list__item how-item">
                <span class="how-list__item-count">3</span>
                <p class="how-item__desc">
                    {{ $t("register.howTo.thirdDescription") }}
                </p>
                <router-link :to="{ path: '/academy' }">
                    <button class="how-item__more glob-btn btn-reset" type="button">
                        {{ $t("register.howTo.more") }}
                    </button>
                </router-link>
            </li>
            <li class="how-list__item how-item">
                <span class="how-list__item-count">4</span>
                <p class="how-item__desc">
                    {{ $t("register.howTo.fourthDescription") }}
                    <a :href="link" target="_blank">{{ link }}</a>
                </p>
                <svg width="32" height="32" viewBox="0 0 20 20" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15 6.667H8.332c-.92 0-1.667.746-1.667 1.666V15c0 .92.746 1.667 1.667 1.667h6.666c.92 0 1.667-.747 1.667-1.667V8.333c0-.92-.746-1.666-1.667-1.666Z"
                    ></path>
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.469 3.468A2.167 2.167 0 0 1 5 2.833h6.666A2.167 2.167 0 0 1 13.834 5v1.667a.5.5 0 0 1-1 0V5a1.167 1.167 0 0 0-1.167-1.167H5.001A1.167 1.167 0 0 0 3.834 5v6.667a1.167 1.167 0 0 0 1.167 1.166h1.666a.5.5 0 1 1 0 1H5.001a2.167 2.167 0 0 1-2.167-2.166V5c0-.575.228-1.126.635-1.532Z"
                    ></path>
                </svg>
            </li>
            <li class="how-list__item how-item how-item--reg">
                <span class="how-list__item-count">5</span>
                <p class="how-item__desc">{{ $t("register.howTo.fifthDescription") }}</p>

                <router-link class="how-item__register btn-reset" tag="button" :to="{ name: 'signup' }">
                    {{ $t("register.register") }}
                </router-link>
            </li>
        </ul>
        <RegisterHelp />
    </section>
</template>

<script>
import RegisterHelp from "@/components/Register/RegisterHelp.vue"
export default {
    components: {
        RegisterHelp,
    },
    computed: {
        link() {
            return `https://${process.env.VUE_APP_BASE_API_URL}${window.location.pathname}`
        },
    },
    // data() {},
}
</script>

<style lang="scss" scoped>
.how {
    max-width: 920px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    &__title {
        text-align: center;
    }

    &-list {
        margin-bottom: var(--third-offset);
        &__item {
            display: flex;
            align-items: center;
            @media screen and (max-width: 600px) {
                display: grid;
                grid-template-columns: max-content 1fr;
                gap: 15px;
                align-items: start;
            }
            svg {
                margin-left: 10px;
                flex-shrink: 0;
                @media screen and (max-width: 600px) {
                    margin-left: 0;
                    grid-column: 2 / 3;
                    width: 26px;
                    height: 26px;
                }
            }
        }
        &__item-count {
            margin-right: 12px;
            font-weight: 600;
            font-size: 30px;
            line-height: 45px;
            @media screen and (max-width: 600px) {
                margin-right: 0;
                line-height: 1.2;
            }
        }
    }
}

.how-item {
    padding: 28px 20px 28px 30px;
    border-radius: 10px;
    border-radius: 39px;
    background-color: var(--color-cinder);
    @media screen and (max-width: 600px) {
        padding: 30px 15px;
    }
    &--reg {
        background-color: var(--color-yellow);
        color: var(--color-cinder);
    }

    &__register {
        padding: 6px 24px;
        border-radius: 10px;
        font-size: 14px;
        color: var(--color-cinder);
        background-color: var(--color-white);
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        @media screen and (max-width: 600px) {
            grid-column: 2 / 3;
            margin: 0;
            margin-right: auto;
        }
    }

    &:not(:last-child) {
        margin-bottom: 15px;
    }

    &__desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        max-width: 690px;
        min-width: 1px;
        a {
            display: inline-block;
            max-width: 100%;
            word-wrap: break-word;
        }

        @media screen and (max-width: (600px)) {
            font-size: 14px;
            line-height: 21px;
        }
        &--lg {
            max-width: 782px;
        }
    }
    &__more {
        margin-left: auto;
        padding: 6px 22px;
        border-radius: 10px;
        @media screen and (max-width: 600px) {
            margin: 0;
            padding: 7px 28px;
            margin-right: auto;
            grid-column: 2 / 3;
        }
    }
    span {
        font-size: 30px;
        width: 17px;
        @media screen and (max-width: (576px)) {
            width: 15px;
            font-size: 24px;
        }
    }
}
</style>
